<template>
	<div class="container-dashboard-encaste">
		<div class="card container-select-year">
			<v-select
				v-model="season"
				label="season"
				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				:options="seasons"
				:clearable="false"
				:reduce="(opt) => opt.id"
				@input="setSeasons($event)"
			/>
		</div>
		<div class="graphs-container">
			<DashboardChicksBorn v-if="season" :start_date="start_date" :end_date="end_date" class="dashboard-chicks" />
			<DashboardGenderOfLiveChickens
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-chick-genders"
			/>
			<DashboardCountPadrillosAndMadrillas
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-specimens"
			/>

			<DashboardFertilidad
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-fertitlidad"
			/>
			<DashboardEfectividad
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-efectividad"
			/>

			<DashboardPosturasByStatus
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-posturas"
			/>
			<DashboardEggsRegister v-if="season" :start_date="start_date" :end_date="end_date" class="dashboard-eggs" />

			<DashboardMadrillas
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-madrillas"
			/>

			<DashboardPadrillos
				v-if="season"
				:start_date="start_date"
				:end_date="end_date"
				class="dashboard-padrillos"
			/>
		</div>
	</div>
</template>

<script>
import DashboardChicksBorn from "@/views/amg/encaste/dashboard-encaste/components/DashboardChicksBorn.vue"
import DashboardGenderOfLiveChickens from "@/views/amg/encaste/dashboard-encaste/components/DashboardGenderOfLiveChickens.vue"
import DashboardPosturasByStatus from "@/views/amg/encaste/dashboard-encaste/components/DashboardPosturasByStatus.vue"
import DashboardCountPadrillosAndMadrillas from "@/views/amg/encaste/dashboard-encaste/components/DashboardCountPadrillosAndMadrillas.vue"
import DashboardEggsRegister from "@/views/amg/encaste/dashboard-encaste/components/DashboardEggsRegister.vue"
import DashboardFertilidad from "@/views/amg/encaste/dashboard-encaste/components/DashboardFertilidad.vue"
import DashboardMadrillas from "@/views/amg/encaste/dashboard-encaste/components/DashboardMadrillas.vue"
import DashboardPadrillos from "@/views/amg/encaste/dashboard-encaste/components/DashboardPadrillos.vue"
import DashboardEfectividad from "@/views/amg/encaste/dashboard-encaste/components/DashboardEfectividad.vue"

export default {
	name: "DashboardEncaste",
	components: {
		DashboardChicksBorn,
		DashboardGenderOfLiveChickens,
		DashboardPosturasByStatus,
		DashboardCountPadrillosAndMadrillas,
		DashboardEggsRegister,
		DashboardFertilidad,
		DashboardEfectividad,
		DashboardMadrillas,
		DashboardPadrillos,
	},
	data() {
		return {
			start_date: null,
			end_date: null,
			season: null,
		}
	},
	mounted() {
		const { id } = this.seasons[0]
		this.season = id
		this.setSeasons(id)
	},
	methods: {
		setSeasons(id) {
			const { start, end } = this.seasons.find((season) => season.id == id)
			this.start_date = start
			this.end_date = end
		},
	},
}
</script>

<style lang="scss" scoped>
.container-select-year {
	padding: 2rem;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
}
.container-dashboard-encaste {
	.graphs-container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		grid-template-areas:
			"dashboard-chicks"
			"dashboard-chick-genders"
			"dashboard-specimens"
			"dashboard-fertitlidad"
			"dashboard-efectividad"
			"dashboard-posturas"
			"dashboard-eggs"
			"dashboard-madrillas"
			"dashboard-padrillos";
		@media screen and (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				"dashboard-chicks dashboard-chick-genders"
				"dashboard-specimens dashboard-specimens"
				"dashboard-fertitlidad dashboard-efectividad"
				"dashboard-posturas dashboard-eggs"
				"dashboard-madrillas dashboard-padrillos";
		}
		@media screen and (min-width: 1400px) {
			grid-template-columns: repeat(8, 1fr);
			grid-template-areas:
				// "dashboard-chicks dashboard-chicks dashboard-chicks dashboard-chick-genders dashboard-chick-genders dashboard-chick-genders dashboard-specimens dashboard-specimens"

				"dashboard-chicks dashboard-chicks dashboard-chicks dashboard-chicks dashboard-chicks dashboard-chick-genders dashboard-chick-genders dashboard-specimens"
				"dashboard-fertitlidad dashboard-fertitlidad dashboard-fertitlidad dashboard-fertitlidad dashboard-efectividad dashboard-efectividad dashboard-efectividad dashboard-efectividad"
				"dashboard-posturas dashboard-posturas dashboard-posturas dashboard-posturas dashboard-eggs dashboard-eggs dashboard-eggs dashboard-eggs"
				"dashboard-madrillas dashboard-madrillas dashboard-madrillas dashboard-madrillas dashboard-padrillos dashboard-padrillos dashboard-padrillos dashboard-padrillos";
		}
		.dashboard-chicks {
			grid-area: dashboard-chicks;
		}

		.dashboard-chick-genders {
			grid-area: dashboard-chick-genders;
		}

		.dashboard-specimens {
			grid-area: dashboard-specimens;
		}

		.dashboard-fertitlidad {
			grid-area: dashboard-fertitlidad;
		}

		.dashboard-efectividad {
			grid-area: dashboard-efectividad;
		}

		.dashboard-posturas {
			grid-area: dashboard-posturas;
		}

		.dashboard-eggs {
			grid-area: dashboard-eggs;
		}

		.dashboard-madrillas {
			grid-area: dashboard-madrillas;
		}

		.dashboard-padrillos {
			grid-area: dashboard-padrillos;
		}

		// @media screen and (min-width: 900px) {
		// 	grid-template-columns: 1fr 1fr;
		// }

		// @media screen and (min-width: 1400px) {

		// }
		.col-span-1 {
			grid-column: span 1 / span 1;
		}
		.md\:col-span-1 {
			@media screen and (min-width: 900px) {
				grid-column: span 1 / span 1;
			}
		}
		.lg\:col-span-1 {
			@media screen and (min-width: 1400px) {
				grid-column: span 1 / span 1;
			}
		}
		.col-span-2 {
			grid-column: span 2 / span 2;
		}
		.md\:col-span-2 {
			@media screen and (min-width: 900px) {
				grid-column: span 2 / span 2;
			}
		}
		.lg\:col-span-2 {
			@media screen and (min-width: 1400px) {
				grid-column: span 2 / span 2;
			}
		}
		.col-span-3 {
			grid-column: span 3 / span 3;
		}
		.md\:col-span-3 {
			@media screen and (min-width: 900px) {
				grid-column: span 3 / span 3;
			}
		}
		.lg\:col-span-3 {
			@media screen and (min-width: 1400px) {
				grid-column: span 3 / span 3;
			}
		}
		.col-span-4 {
			grid-column: span 4 / span 4;
		}
		.md\:col-span-4 {
			@media screen and (min-width: 900px) {
				grid-column: span 4 / span 4;
			}
		}
		.lg\:col-span-4 {
			@media screen and (min-width: 1400px) {
				grid-column: span 4 / span 4;
			}
		}

		.col-span-8 {
			grid-column: span 8 / span 8;
		}
		.md\:col-span-4 {
			@media screen and (min-width: 900px) {
				grid-column: span 4 / span 4;
			}
		}
	}
}
</style>

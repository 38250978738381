import axios from "@/axios"

const url = "/api/encaste/dashboard"

class EncasteDashboardService {
	async getChickensBotn({ start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-chickens-born`, {
				params: {
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getGenderOfLiveChickens({ start_date, end_date, is_accumulate }) {
		try {
			const { data } = await axios.get(`${url}/get-gender-of-live-chickens`, {
				params: {
					start_date,
					end_date,
					is_accumulate,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getPosturasByStatus({ start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-posturas-by-status`, {
				params: {
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getEggsRegister({ start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-eggs-registered`, {
				params: {
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getFertilidad({ start_date, end_date, category, min_eggs_registered }) {
		try {
			const { data } = await axios.get(`${url}/get-fertilidad`, {
				params: {
					start_date,
					end_date,
					category,
					min_eggs_registered,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getEfectividad({ start_date, end_date, category, min_born }) {
		try {
			const { data } = await axios.get(`${url}/get-efectividad`, {
				params: {
					start_date,
					end_date,
					category,
					min_born,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getCountPadrillosAndMadrillas({ start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-specimens-with-living-children`, {
				params: {
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getCountPadrillosAndMadrillasDetails({ start_date, end_date, category, page, perpage, search }) {
		try {
			const { data } = await axios.get(`${url}/get-specimens-with-living-children-details`, {
				params: {
					start_date,
					end_date,
					category,
					page,
					perpage,
					search,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getUnbornByMadrillas({ per_page, page, start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-no-nacidos-by-madrilla`, {
				params: {
					per_page,
					page,
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getUnbornByPadrillos({ per_page, page, start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-no-nacidos-by-padrillo`, {
				params: {
					per_page,
					page,
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getUnbornByMadrillasDetail({ per_page, page, start_date, end_date, madrilla_id }) {
		try {
			const { data } = await axios.get(`${url}/get-no-nacidos-by-madrilla-details`, {
				params: {
					per_page,
					page,
					start_date,
					end_date,
					madrilla_id,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getUnbornByPadrillosDetail({ per_page, page, start_date, end_date, padrillo_id }) {
		try {
			const { data } = await axios.get(`${url}/get-no-nacidos-by-padrillo-details`, {
				params: {
					per_page,
					page,
					start_date,
					end_date,
					padrillo_id,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}
}
export default new EncasteDashboardService()

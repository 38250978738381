<template>
	<div class="container-card-dashboard card mb-0">
		<div class="header p-2">
			<b-row>
				<b-col cols="12" md="10">
					<div class="d-flex align-items-center">
						<h1>Madrillas / Ranking de pollos no nacidos</h1>
					</div>
				</b-col>
				<b-col cols="12" md="2">
					<div class="d-flex justify-content-end">
						<b-button-group size="sm">
							<b-button
								class="btn-icon"
								variant="outline-secondary"
								:disabled="pagination.page <= 1"
								@click="changePagination(-1, 'paginator')"
							>
								<feather-icon icon="ChevronLeftIcon" />
							</b-button>
							<b-button
								class="btn-icon"
								variant="outline-secondary"
								:disabled="pagination.page >= pagination.maxPage"
								@click="changePagination(1, 'paginator')"
							>
								<feather-icon icon="ChevronRightIcon" />
							</b-button>
						</b-button-group>
					</div>
				</b-col>
			</b-row>
		</div>
		<div class="body p-2">
			<div :key="keyChart" id="chart-posturas">
				<apexchart
					type="bar"
					height="350"
					:options="chartOptions"
					:series="series"
					@click="openDetail"
				></apexchart>
			</div>
		</div>

		<UnbornByMadrillaModal
			v-if="showDetail.show"
			:start_date="start_date"
			:end_date="end_date"
			:info="showDetail.info"
			@closing="showDetail.show = false"
		/>
	</div>
</template>

<script>
import encasteDashboardService from "@/services/encaste/encasteReports.service"
import VueApexCharts from "vue-apexcharts"
import UnbornByMadrillaModal from "@/views/amg/encaste/dashboard-encaste/components/UnbornByMadrillaModal.vue"

export default {
	name: "DashboardMadrillas",
	components: {
		apexchart: VueApexCharts,
		UnbornByMadrillaModal,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			specimens: [],
			series: [
				{
					name: "",
					data: [],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 10,
						dataLabels: {
							position: "top", // top, center, bottom
						},
					},
				},
				colors: ["#7367F0"],
				// colors: ["#4b4b4b", "#28c76f", "#00cfe8", "#ea5455"],
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val
					},
					offsetY: -20,
					style: {
						fontSize: "12px",
						colors: ["#7367F0"],
					},
				},
				legend: {
					show: false,
					showForSingleSeries: true,
					customLegendItems: ["Ranking"],
					markers: {
						// fillColors: ["#00E396", "#775DD0"],
					},
					labels: {
						colors: "#9aa0ac",
					},
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					labels: {
						rotate: -45,
					},
					categories: [],
					// tickPlacement: 'on'
					labels: {
						style: {
							colors: "#9aa0ac",
							// fontSize: "14px",
						},
					},
				},
				yaxis: {
					title: {
						text: "Ranking",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: "#9aa0ac",
							// fontSize: "14px",
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return "" + val
						},
					},
				},
			},
			totalPosturas: 0,
			keyChart: 0,
			category: 1,
			min_eggs_registered: 0,
			pagination: {
				page: 1,
				maxPage: 2,
			},
			showDetail: {
				show: false,
				info: null,
			},
		}
	},
	async mounted() {
		await this.initChart()
	},
	computed: {
		sumEggs: () => (items) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue
			}, 0)
		},
	},
	methods: {
		openDetail(event, chartContext, config) {
			if (event.target.classList.contains("apexcharts-bar-area")) {
				this.showDetail.info = this.specimens[config.dataPointIndex]
				this.showDetail.show = true
			}
		},
		changePagination(diff) {
			this.pagination.page += diff
		},
		async initChart() {
			const res = await encasteDashboardService.getUnbornByMadrillas({
				per_page: 10,
				page: this.pagination.page,
				start_date: this.start_date,
				end_date: this.end_date,
			})
			this.specimens = res.no_nacidos.data
			this.pagination.maxPage = res.no_nacidos.last_page
			this.chartOptions.xaxis.categories = res.no_nacidos.data.map(
				(item) =>
					`${item.madrilla.plate} ${item.madrilla.alias ? "/" : ""} ${
						item.madrilla.alias ? item.madrilla.alias : ""
					}`
			)
			this.series[0] = {
				name: "",
				data: res.no_nacidos.data.map((item) => item.quantity),
			}

			this.keyChart = this.keyChart + 1
		},
		async setCategory(category) {
			this.category = category
			await this.initChart()
		},
	},
	watch: {
		async start_date() {
			await this.initChart()
		},
		async "pagination.page"() {
			await this.initChart()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}
</style>
